import React from "react";

import UpdateAvailableNotification from "../UpdateAvailableNotification/UpdateAvailableNotification";

import { register } from "../../serviceWorkerRegistration";
// import * as serviceWorkerRegistration from;

const ServiceWorkerWrapper = () => {
  const [reloadShown, setReloadShown] = React.useState(false);
  const [, setInstallingWorker] = React.useState(null);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    setReloadShown(true);
    setInstallingWorker(registration.installing);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    register({ onUpdate: onSWUpdate });
  }, []);

  // Show a Snackbar only on `waiting` state of the SW.
  const reloadPage = () => {
    // installingWorker?.postMessage({ type: 'SKIP_WAITING' });
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setReloadShown(false);
    // window.location.reload();
  };

  return (
    <UpdateAvailableNotification
      open={reloadShown}
      onClick={reloadPage}
      onClose={() => setReloadShown(false)}
      // data-test-id="screens-new-version-snackbar"
    />
  );
};

export default ServiceWorkerWrapper;
